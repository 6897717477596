/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNFTCertificates = /* GraphQL */ `
  mutation CreateNFTCertificates(
    $input: CreateNFTCertificatesInput!
    $condition: ModelNFTCertificatesConditionInput
  ) {
    createNFTCertificates(input: $input, condition: $condition) {
      id
      path
      nftID
      nftName
      nftImage
      nftAddress
      contractSymbol
      walletAddress
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateNFTCertificates = /* GraphQL */ `
  mutation UpdateNFTCertificates(
    $input: UpdateNFTCertificatesInput!
    $condition: ModelNFTCertificatesConditionInput
  ) {
    updateNFTCertificates(input: $input, condition: $condition) {
      id
      path
      nftID
      nftName
      nftImage
      nftAddress
      contractSymbol
      walletAddress
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteNFTCertificates = /* GraphQL */ `
  mutation DeleteNFTCertificates(
    $input: DeleteNFTCertificatesInput!
    $condition: ModelNFTCertificatesConditionInput
  ) {
    deleteNFTCertificates(input: $input, condition: $condition) {
      id
      path
      nftID
      nftName
      nftImage
      nftAddress
      contractSymbol
      walletAddress
      description
      createdAt
      updatedAt
    }
  }
`;
