import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import { useState, useEffect } from 'react'
import { API } from "aws-amplify"

function NFTCertificateDetail({ NFTCertificate, address }) {
  const [yourEns, setYourEns] = useState()
  const [contractCopied, setContractCopied] = useState(false)
  const [walletCopied, setWalletCopied] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [currentAddress, setCurrentAddress] = useState()

  useEffect(() => {
    if (NFTCertificate && !fetched) getENS(NFTCertificate.walletAddress)

    setCurrentAddress(address)
  })

  const getENS = async (account) => {
    setFetched(true)

    let path = `/nfts/${account}?ens=1&pageSize=1`

    if (process.env.REACT_APP_TESTNET == 'true') {
      path += '&testnet=1'
    }

    try {
      const { nfts: listEns } = await API.get('apicd3dea9f', path)

      setYourEns(listEns[0])
    } catch (error) {
      console.log(error)
    }
  }

  const copyContract = async () => {
    try {
      await navigator.clipboard.writeText(NFTCertificate?.nftAddress);
      setContractCopied(true)

      setTimeout(() => {
        setContractCopied(false)
      }, 3000)
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const copyWallet = async () => {
    try {
      await navigator.clipboard.writeText(NFTCertificate?.walletAddress);
      setWalletCopied(true)

      setTimeout(() => {
        setWalletCopied(false)
      }, 3000)
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <div className='max-w-[400px] m-auto px-5 py-10 pt-0'>
      <div className='text-center font-bold uppercase text-2xl'>
        { NFTCertificate?.nftName }
      </div>
      <div className='uppercase mb-3 text-end text-xs text-stone-500'>GASHO-{ NFTCertificate?.id }</div>
      <div className='bg-white bg-opacity-10 rounded-lg box-shadow'>
        <Badge.Ribbon text='Owner' color='red' className={{hidden: !currentAddress || currentAddress != NFTCertificate?.walletAddress}}>
          <img src={NFTCertificate?.nftImage} className="rounded-t-lg w-full" />
        </Badge.Ribbon>
        <div className='p-5 space-y-4'>
          <div className='text-sm'>
            <div className="text-gray-500">
              <span>{NFTCertificate?.description}</span>
            </div>
            <div className="border-b-[1px] my-3"></div>
            <div className="space-y-1.5 text-stone-700">
              {
                NFTCertificate?.contractSymbol && (
                  <div className='truncate'>Symbol: <b>{NFTCertificate?.contractSymbol}</b></div>
                )
              }
              <div className='flex justify-between space-x-1'>
                <span>Address:</span>
                <span onClick={copyContract} className="bg-stone-200 px-1 rounded-md truncate pr-5 relative">
                  <span>{NFTCertificate?.nftAddress}</span>
                  {
                    contractCopied ? <CheckCircleOutlined className='text-primary absolute right-1 top-1' /> : <CopyOutlined className='absolute right-1 top-1' />
                  }
                </span>
              </div>
              <div className='flex justify-between space-x-1'>
                <span className='min-w-[110px]'>Wallet Address:</span>
                <span onClick={copyWallet} className="bg-stone-200 px-1 rounded-md truncate pr-5 relative">
                  <span>{NFTCertificate?.walletAddress}</span>
                  {
                    walletCopied ? <CheckCircleOutlined className='text-primary absolute right-1 top-1' /> : <CopyOutlined className='absolute right-1 top-1' />
                  }
                </span>
              </div>
              {
                yourEns && (
                  <div className='flex justify-between space-x-1'>
                    Wallet Domain:
                    <span>{yourEns?.title}</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NFTCertificateDetail
