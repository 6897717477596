import './App.css';
import 'antd/dist/reset.css';
import React from "react";
import { Amplify,  Auth  } from 'aws-amplify';

import awsExports from './aws-exports';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App() {
  return (
    <Router initialEntries={["/"]}>
      <div className="app">
        <div className="body">
          <Routes>
            <Route path="/certificate/:itemId" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
