/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNFTCertificates = /* GraphQL */ `
  query GetNFTCertificates($id: ID!) {
    getNFTCertificates(id: $id) {
      id
      path
      nftID
      nftName
      nftImage
      nftAddress
      contractSymbol
      walletAddress
      description
      createdAt
      updatedAt
    }
  }
`;
export const listNFTCertificates = /* GraphQL */ `
  query ListNFTCertificates(
    $filter: ModelNFTCertificatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNFTCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        path
        nftID
        nftName
        nftImage
        nftAddress
        contractSymbol
        walletAddress
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
